import { useEffect } from 'react';
import Chat from './app/components/Chat';

export default function AppPage() {

    return (
        <div className="container mx-auto px-4 lg:px-0">
            <Chat />
        </div>
    )
}