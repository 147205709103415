import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth';

import React from 'react';
import Btns from './components/auth/Btns';

function Signin() {
  
  const { user } = useAuth();
  const navigate = useNavigate();

  return (
    <div className="text-white relative container mx-auto z-30">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 px-4 lg:px-0">
        { user ? (
          navigate('/')
        ) : (
          <div className='col-span-1 lg:col-span-3 text-center'>
            <Btns />
          </div>
        )}
      </div>
    </div>
  );
}
export default Signin;