import React, { useEffect, useState } from "react";
import { getPortalUrl, getPremiumStatus } from "../../../../../api/payments/StripePayments";
import { app, auth } from "../../../../../firebase";

export default function Profile({ user }) {

    const [isPremium, setIsPremium] = useState(false);

    useEffect(() => {
        const checkPremium = async () => {
          const newPremiumStatus = auth.currentUser
            ? await getPremiumStatus(app)
            : false;
          setIsPremium(newPremiumStatus);
        };
    
        checkPremium();
    }, []);

    const redirectPortal = async () => {
        const portalUrl = await getPortalUrl(app);
        window.location.href = portalUrl;
    }

    return (
        <div className="bg-blur bg-[#141414] bg-opacity-50 rounded-2xl p-8">
            <div className="grid grid-cols-2">
                <div>
                    <p className="font-black uppercase text-xs text-[#F5F5F7]/[0.5]">User</p>
                    <p className="text-xl text-syne">{ user.name } { user.surname }</p>
                </div>
                <button onClick={redirectPortal}>
                    <div className="text-right self-center text-[#F5F5F7]/[0.5]">
                        <p>{ user.credits } Credits</p>
                        <p className="text-xs font-bold opacity-50">{ !isPremium? ( "Free Plan" ) : ( "Premium Plan" )}</p>
                    </div>
                </button>
            </div>
        </div>
    )
}