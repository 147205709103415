export default function Status() {
    return (
        <div className="bg-blur bg-[#141414] bg-opacity-50 rounded-2xl p-8">
            <div className="grid grid-cols-2">
                <div>
                    <p className="font-black uppercase text-xs text-[#F5F5F7]/[0.5]">Network</p>
                    <p className="text-xl text-syne">Status</p>
                </div>
                <div className="text-right self-center text-[#F5F5F7]/[0.5]">
                    <p className="text-green-500"><i className="fas fa-circle status"></i></p>
                </div>
            </div>
        </div>
    )
}