import React, { useState, useEffect } from "react";
import { SpeedInsights } from "@vercel/speed-insights/react"

import { getAssistantsByUser } from './app/handlers/APIHandler';

import { useAuth } from './Auth';
import Sidebar from './app/components/Sidebar';
import YourApps from "./app/components/YourApps";
import NeedAccount from './app/components/NeedAccount';

function Apps() {
    const { currentUser, user } = useAuth();
    const [assistants, setAssistants] = useState([]);

    useEffect(() => {
        if (currentUser && user) {
            getAssistantsByUser(currentUser.uid)
                .then((assistants) => {
                    setAssistants(assistants);
                })
                .catch(error => {
                    console.error('Error fetching assistants:', error);
                });
        }
    }, [currentUser, user]);

    return (
        <>
            <SpeedInsights/>
            {user ? (
                <div className="text-white relative container mx-auto z-30">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 px-4 lg:px-0">
                        <div className="grid grid-cols-1 gap-8 lg:col-span-2">
                            <YourApps assistants={assistants} />
                        </div>
                        <Sidebar user={user} />
                    </div>
                </div>
            ) : (
                <NeedAccount />
            )}
        </>
    );
}

export default Apps;