import OpenAI from 'openai';
import { db } from '../../../firebase';
import { updateDoc, setDoc, collection, getDoc, doc, getDocs, deleteDoc, runTransaction } from "firebase/firestore";

import { editAlert, errorAlert, createdAlert, deleteAlert } from './Alerts.js'

const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
});

const getCredits = async (userId) => {
    try {
        if (!userId) {
            throw new Error("User ID is null or undefined");
        }

        const userRef = doc(db, "Users", userId);
        const userSnapshot = await getDoc(userRef);
        
        if (!userSnapshot.exists()) {
            throw new Error(`Nessun utente trovato con ID: ${userId}`);
        }

        const userData = userSnapshot.data();
        const credits = userData.credits;

        return credits;
    } catch (error) {
        console.error('Errore durante il recupero dei crediti:', error);
        throw error;
    }
}

const addAItoUser = async (userId, assistantId) => {
    try {
        const userRef = doc(db, "Users", userId);
        const userSnapshot = await getDoc(userRef);

        if (!userSnapshot.exists()) {
            throw new Error(`Nessun utente trovato con ID: ${userId}`);
        }

        const userData = userSnapshot.data();
        const appIds = userData.apps || [];

        if (appIds.includes(assistantId)) {
            throw new Error(`L'utente con ID ${userId} ha già l'applicazione con ID ${assistantId}`);
        }

        appIds.push(assistantId);

        await runTransaction(db, async (transaction) => {
            const userDocRef = doc(collection(db, "Users"), userId);
            transaction.update(userDocRef, {
                apps: appIds,
            });
        });

        return true;

    } catch (error) {
        console.error('Errore durante l\'aggiunta dell\'applicazione all\'utente:', error);
        throw error;
    }
}

const userHasAssistant = async (userId, assistantId) => {
    try {
        const userRef = doc(db, "Users", userId);
        const userSnapshot = await getDoc(userRef);

        if (!userSnapshot.exists()) {
            throw new Error(`Nessun utente trovato con ID: ${userId}`);
        }

        const userData = userSnapshot.data();
        const appIds = userData.apps || [];

        return appIds.includes(assistantId);
    } catch (error) {
        console.error('Errore durante il recupero delle applicazioni dell\'utente:', error);
        throw error;
    }
}

const removeCredit = async (userId, credit) => {
    try {
        const userRef = doc(db, "Users", userId);
        const userSnapshot = await getDoc(userRef);

        if (!userSnapshot.exists()) {
            throw new Error(`Nessun utente trovato con ID: ${userId}`);
        }

        const userData = userSnapshot.data();
        const currentCredits = userData.credits;

        const newCredits = currentCredits - credit;
        
        await runTransaction(db, async (transaction) => {
            const userDocRef = doc(collection(db, "Users"), userId);
            transaction.update(userDocRef, {
                credits: newCredits,
            });
        });
    } catch (error) {
        console.error('Errore durante la rimozione dei crediti:', error);
        throw error;
    }
}

const getFilesFromAssistant = async (assistantId) => {
    try {
        const assistantRef = doc(db, "Applications", assistantId);
        const assistantSnapshot = await getDoc(assistantRef);
        if (!assistantSnapshot.exists()) {
            throw new Error(`Nessun assistente trovato con ID: ${assistantId}`);
        }
        const assistantData = assistantSnapshot.data();
        
        const filesData = await Promise.all(assistantData.file_ids.map(async (fileId) => {
            try {
                const file = await getFileFromOpenAI(fileId);
                return file;
            } catch (error) {
                console.error(`Errore durante il recupero del file con ID ${fileId}:`, error);
                return null;
            }
        }));

        const validFiles = filesData.filter(file => file !== null);

        return validFiles;
    } catch (error) {
        console.error("Errore durante il recupero dei file dell'assistente:", error);
        throw error;
    }
}


const getFileFromOpenAI = async (fileId) => {
    try {
        const file = await openai.files.retrieve(fileId);
        return file;
    } catch (error) {
        console.error('Errore durante il recupero del file:', error);
        throw error;
    }
}

const createAssistant = async (assistant, name, desc, model, instructions, tools, files, user, visibility, category) => {
    
    try {
        let newAssistant;
        let fileIds = [];
       
        if (files != null) {
            if (Array.isArray(files) && files.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    if (file instanceof File) {
                        const uploadedFile = await createFile(file);
                        fileIds.push(uploadedFile.id);
                    } else {
                        fileIds.push(file.id);
                    }
                }
            }
        }

        if (assistant == null) {
            newAssistant = await openai.beta.assistants.create({
                name: name,
                instructions: instructions,
                model: model,
                tools: tools,
                file_ids: fileIds
            });
            await addAItoUser(user.id, newAssistant.id);
            createdAlert();
        } else {
            newAssistant = await openai.beta.assistants.update(assistant, {
                name: name,
                instructions: instructions,
                model: model,
                tools: tools,
                file_ids: fileIds,
            });
            editAlert();
        }

        const assistantId = newAssistant.id;

        const docRef = await setDoc(doc(collection(db, "Applications"), assistantId), {
            id: assistantId,
            name: name,
            desc: desc,
            instructions: instructions,
            model: model,
            tools: tools,
            file_ids: fileIds,
            author: user.id,
            visibility: visibility,
            category: category
        });

        return docRef;

    } catch (error) {
        console.log(error)
        errorAlert();
    }
};

const getAssistant = async (assistantId) => {
    const assistantRef = doc(db, "Applications", assistantId);
    const assistantSnapshot = await getDoc(assistantRef);
    if (!assistantSnapshot.exists()) {
        throw new Error(`Nessun documento trovato con ID: ${assistantId}`);
    }
    return assistantSnapshot.data();
}

const emailExists = async (email) => {
    try {
        const userRef = collection(db, "Users");
        const userSnapshot = await getDocs(userRef);
        const userList = userSnapshot.docs.map(doc => doc.data());
        const emailList = userList.map(user => user.email);
        return emailList.includes(email);
    } catch (error) {
        console.error('Errore durante il recupero delle email:', error);
        throw error;
    }
}

const getCategories = async () => {
    try {
        const assistantsRef = collection(db, "Applications");
        const querySnapshot = await getDocs(assistantsRef);
        
        const categoriesSet = new Set();
        
        querySnapshot.forEach(doc => {
            const assistantData = doc.data();
            if (assistantData.visibility === true) {
                categoriesSet.add(assistantData.category);
            }
        });
        
        const categories = Array.from(categoriesSet);
        
        return categories;
    } catch (error) {
        console.error('Errore durante il recupero delle categorie:', error);
        throw error;
    }
};

const getPublicAssistantsByCategory = async (category) => {
    try {
        const assistantsRef = collection(db, "Applications");
        const querySnapshot = await getDocs(assistantsRef);
        
        const publicAssistants = [];
        
        querySnapshot.forEach(doc => {
            const assistantData = doc.data();
            
            // Verifica se l'assistente è pubblico e appartiene alla categoria specificata
            if (assistantData.visibility === true && assistantData.category === category) {
                publicAssistants.push(assistantData);
            }
        });

        // Se ci sono più di 4 assistenti, scegli casualmente solo 4 di essi
        const randomPublicAssistants = publicAssistants.sort(() => 0.5 - Math.random()).slice(0, 4);

        return randomPublicAssistants;
    } catch (error) {
        console.error('Errore durante il recupero degli assistenti pubblici per categoria:', error);
        throw error;
    }
};


const getAssistantsByUser = async (userId) => {
    try {
        const userRef = doc(db, "Users", userId);
        const userSnapshot = await getDoc(userRef);

        if (!userSnapshot.exists()) {
            throw new Error(`Nessun utente trovato con ID: ${userId}`);
        }

        const userData = userSnapshot.data();
        const appIds = userData.apps || [];

        const appsPromises = appIds.map(async (appId) => {
            const appRef = doc(db, "Applications", appId);
            const appSnapshot = await getDoc(appRef);
            if (appSnapshot.exists()) {
                return appSnapshot.data();
            } else {
                console.warn(`L'applicazione con ID ${appId} non esiste o non è accessibile.`);
                return null;
            }
        });

        const apps = await Promise.all(appsPromises);
        return apps.filter(app => app !== null);
    } catch (error) {
        console.error('Errore durante il recupero delle applicazioni dell\'utente:', error);
        throw error;
    }
};

const createFile = async (file) => {
    try {
        const createdFile = await openai.files.create({
            file: file,
            purpose: "assistants",
        });

        return createdFile;
    } catch (error) {
        console.error('Errore durante il caricamento del file:', error);
        throw error;
    }
};

const getAssistants = async () => {
    const assistantsRef = collection(db, "Applications");
    const assistantsSnapshot = await getDocs(assistantsRef);
    const assistantsList = assistantsSnapshot.docs.map(doc => doc.data());
    return assistantsList;
}

const isAssistantOwner = async (assistantId, userId) => {
    try {
        const assistantRef = doc(db, "Applications", assistantId);
        const assistantSnapshot = await getDoc(assistantRef);
        if (!assistantSnapshot.exists()) {
            throw new Error(`Nessun assistente trovato con ID: ${assistantId}`);
        }
        const assistantData = assistantSnapshot.data();
        return assistantData.author === userId;
    } catch (error) {
        console.error('Errore durante il recupero del proprietario dell\'assistente:', error);
        throw error;
    }
}

const deleteAssistant = async (assistantId, userId) => {
    const assistantRef = doc(db, "Applications", assistantId);
    await deleteDoc(assistantRef);

    const userRef = doc(db, "Users", userId);

    // Ottieni i dati dell'utente
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
        // Recupera l'array "apps" dell'utente
        let userApps = userSnap.data().apps || [];

        // Rimuovi l'ID dell'assistente dall'array "apps"
        userApps = userApps.filter(app => app !== assistantId);

        // Aggiorna il documento dell'utente con i nuovi dati
        await updateDoc(userRef, {
            apps: userApps
        });

        // Se necessario, chiamare una funzione per gestire l'eliminazione di avvisi
        deleteAlert();
    } else {
        console.log("Documento utente non trovato.");
    }
}


export { isAssistantOwner, getFileFromOpenAI, getFilesFromAssistant, getPublicAssistantsByCategory, getCategories, addAItoUser, userHasAssistant, getCredits, removeCredit, emailExists, createAssistant, getAssistant, getAssistants, deleteAssistant, createFile, getAssistantsByUser }