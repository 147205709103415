import { useNavigate, useParams } from 'react-router-dom';

import Chat from './components/Chat';
import Settings from './components/Settings.js';

import { useAuth } from '../Auth';
import { useEffect } from 'react';
import { isAssistantOwner } from './handlers/APIHandler.js';

function Editor() {

  const navigate = useNavigate();

  const { assistantId } = useParams();
  const { user } = useAuth();

  useEffect(() => {
    async function checkAssistant() {
      if(user) {
        const valOwner = await isAssistantOwner(assistantId, user.id);
        if (!valOwner) {
          navigate('/');
        }
      } else {
        navigate('/');
      }
    }
    checkAssistant();
  })

  return (
    <div className='container mx-auto px-4 lg:px-0'>
      <div className='grid grid-cols-1 lg:grid-cols-3 gap-8'>
        <div className='lg:col-span-2'>
          <Chat />
        </div>
        <div>
          <Settings assistantId={assistantId} />
        </div>
      </div>
    </div>
  )
  
}

export default Editor;
