import React, { useState, useEffect, createContext, useContext} from 'react';
import { auth } from '../firebase';
import { getUserInfo } from '../api/auth/AuthHandler';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {

    const [ currentUser, setCurrentUser ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ user, setUser ] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (currentUser) {
            getUserInfo(currentUser.uid)
                .then(result => setUser(result))
                .catch(error => console.error('Error fetching user info:', error));
        }
    }, [currentUser]);

    const value = {
        currentUser,
        user
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}