import React, { useState } from 'react';
import { handleLogin } from '../../../../api/auth/AuthHandler';

import { Link, useNavigate } from 'react-router-dom';
import { errorText } from '../../handlers/Alerts';

export default function Btns() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    const submit = async () => {
        try {
            const result = await handleLogin(email, password);
            if (result === true) {
                navigate('/');
            } else {
                errorText('Invalid credentials');
            }
        } catch (error) {
            errorText('Invalid credentials');
        }
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        submit();
    }

    const viewer = () => {
        let x = document.getElementById("password");
        let eye = [document.getElementById("eye"), document.getElementById("eye-m")];

        if (x.type === "password") {
            x.type = "text";

            for (let i = 0; i < eye.length; i++) {
                eye[i].classList.replace('fa-eye', 'fa-eye-slash')
                eye[i].classList.replace('far', 'fas')
            }

        } else {
            x.type = "password";
            
            for (let i = 0; i < eye.length; i++) {
                eye[i].classList.replace('fa-eye-slash', 'fa-eye')
                eye[i].classList.replace('fas', 'far')
            }

        }
    }

    return (
        <div className="grid grid-cols-1 gap-2 mt-16 lg:mt-8 text-black">
            <div>
                <Link to="/signup">
                    <div className="btn-makit font-bold w-full lg:w-1/3 mx-auto btn-hover-effect cursor-pointer">
                        <p className='text-white'>Register Your Account</p>
                    </div>
                </Link>
            </div>
            <div className='text-white opacity-10 my-1 lg:my-2'>
                <p>────<span className='px-4'>or</span>────</p>
            </div>
            <div>
                <input className="w-full lg:w-auto btn-makit" placeholder="luca@makit.ai" type="email" value={email} onChange={handleEmailChange} autoComplete="email"/>
            </div>
            <div className='grid grid-cols-5 gap-2 lg:block'>
                <div className='col-span-4 inline-block'>
                    <input id="password" className="w-full lg:w-auto btn-makit mb-4" type="password" placeholder="∘∘∘∘∘∘∘∘" value={password} onChange={handlePasswordChange} autoComplete="password" />
                    <button className="btn-eye bg-[#141414] backdrop-blur-3xl bg-opacity-20 hidden lg:inline-block" onClick={viewer}><i id="eye" className="far fa-eye"></i></button>
                </div>
                <div>
                    <button className="btn-eye bg-[#141414] backdrop-blur-3xl bg-opacity-20 w-full lg:w-auto block lg:hidden" onClick={viewer}><i id="eye-m" className="far fa-eye"></i></button>
                </div>
            </div>
            <div>
                <button onClick={handleSubmit} type="submit" className="btn-submit right-0 mb-2">Login</button>
                <Link to="/forgot">
                    <p className='text-xs text-white opacity-30'>Forgot password?</p>
                </Link>
            </div>
        </div>
    )
}
