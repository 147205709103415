import { useEffect, useState } from "react"
import Category from "./app/components/market/Category"
import { getCategories } from "./app/handlers/APIHandler"

export default function Marketplace() {

    const [ categories, setCategories ] = useState([])

    useEffect(() => {
        getCategories().then((data) => {
            setCategories(data)
        })
    }, [])

    return (
        <div className="container mx-auto px-4 lg:px-0">
            <div className="grid grid-cols-1 gap-8">
                {categories && (
                    categories.map((category) => {
                        if (category !== "" || category !== null || category !== undefined) {
                            return <Category category={category} key={category}/>
                        } else {
                            return null;
                        }
                    })
                )}
            </div>
        </div>
    )
}
