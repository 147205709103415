import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from 'react-dropzone';
import { useAuth } from './Auth';
import { createAssistant, getAssistantsByUser } from './app/handlers/APIHandler.js'
import { Modal } from './app/components/auth/Modal';
import { useNavigate } from "react-router-dom";
import { errorText } from "./app/handlers/Alerts.js";


export default function Creator() {

    const { user } = useAuth();
    const navigate = useNavigate();

    const [ name, setName ] = useState(null)
    const [ desc, setDesc ] = useState(null)
    const [ model, setModel ] = useState(null)
    const [ instructions, setInstructions ] = useState(null)
    const [ retrive, setRetrive] = useState(false);
    const [ files, setFiles ] = useState([]);

    const [ step, setStep ] = useState(0); 
    const [ completedModals, setCompletedModals ] = useState([]);

    const [ showSubmit, setShowSubmit ] = useState(false);
    const [ showFilesModal, setShowFilesModal] = useState(false);

    const onDrop = useCallback(async (acceptedFiles) => {
        setFiles(prevFiles => prevFiles.concat(acceptedFiles));
    }, []);
      
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    useEffect(() => {
        const checkPlan = async() => {
            if(user) {
                const userPlan = user.premium;
                if (!userPlan) {
                    const userAssistants = await getAssistantsByUser(user.id); 
                    if (userAssistants.length >= 1) {
                        errorText('Free plan users can create only 1 chatbot.');
                        navigate('/');
                    }
                } else if (userPlan) {
                    const userAssistants = await getAssistantsByUser(user.id); 
                    if (userAssistants.length >= 5) {
                        errorText('Premium plan users can create maximum 5 chatbots.');
                        navigate('/');
                    }
                } else {
                    errorText("You need to subscribe to a plan to create another AI");
                    navigate('/pricing');
                }
            }
        }

        checkPlan();
    }, [user, navigate])
  
    const createAI = async () => { 
        if(user) {
            if (retrive === true) {
                let tools = [{"type": "retrieval"}];
                await createAssistant(null, name, desc, "gpt-3.5-turbo-1106", instructions, tools, files, user, false, "Miscellaneous");
            } else {
                let tools = [];
                if(model === null || model === undefined || model === "") {
                    await createAssistant(null, name, desc, "gpt-3.5-turbo-1106", instructions, tools, files, user, false, "Miscellaneous");
                } else {
                    await createAssistant(null, name, desc, "gpt-3.5-turbo-1106", instructions, tools, files, user, false, "Miscellaneous");
                }
            }
        }
    }

    const updateName = (prop) => {
        if (prop != null) {
            setName(prop);
            setCompletedModals(prev => [...prev, 'name']);
            setStep(step + 1);
        }
    };

    const updateDesc = (prop) => {
        if (prop != null) {
            setDesc(prop);
            setCompletedModals(prev => [...prev, 'desc']);
            setStep(step + 1);
        }
    };

    const updateInstructions = (prop) => {
        if (prop != null) {
            setInstructions(prop);
            setCompletedModals(prev => [...prev, 'instructions']);
            setStep(step + 1);
        }
    }

    const updateRetrive = (prop) => {
        const retriveValue = prop === 'true';
        setRetrive(retriveValue);
        setCompletedModals(prev => [...prev, 'retrive']);
        setStep(step + 1);
        if (retriveValue) {
            setShowFilesModal(true);
        } else {
            setShowFilesModal(false);
            setShowSubmit(true);
        }
    }    

    const submit = async() => {
        createAI();
        navigate('/');
    };

    const handleBack = () => {
        setStep(step - 1);
        const lastCompletedModal = completedModals.pop();
        switch (lastCompletedModal) {
            case 'desc':
                setShowSubmit(false);
                break;
            case 'model':
                setShowSubmit(false);
                break;
            case 'instructions':
                setShowSubmit(false);
                break;
            case 'retrive':
                setShowSubmit(false);
                break;
            default:
                break;
        }
    };

    return (
        <div className='relative text-white'>
            <div className=' text-center px-4 lg:px-24 py-24 relative z-50'>
                {step === 0 && (
                    <Modal updateProp={updateName} type="text" text="Name of your Application" tooltip={false} />
                )}
                {step === 1 && (
                    <Modal updateProp={updateDesc} type="text" text="Please define a description" subtext="" evidence="text" required tooltip={true} tooltipText={"Describe in general your application"} />
                )}
                {step === 2 && (
                    <Modal updateProp={updateInstructions} type="text" text="Instructions" tooltip={true} tooltipText={"Describe what your assistant should do"}  />
                )}
                {step === 3 && (
                    <Modal updateProp={updateRetrive} type="retrive" text="Upload Files" tooltip={true} tooltipText={"Answers restricted to your files"} />
                )}
                {showFilesModal && (
                    <>
                    <div className='w-full mb-4 bg-blur rounded-2xl'>
                        <div {...getRootProps()} className='border border-d-makit p-6 opacity-50'>
                                <input {...getInputProps()} />
                                    {
                                        isDragActive ?
                                        <p>Drop the files here</p> :
                                        <p className='cursor-pointer'>Drag 'n' drop some files here <br/> or <b>CLICK</b> to select files</p>
                                    }
                            </div>
                            <ul>
                                {(files) && (
                                    files.map(file => (
                                        <li key={file.name} className='opacity-20'>
                                            {file.name} - <span className='opacity-50'>({(file.size / (1024 * 1024)).toFixed(2)} MB)</span>
                                        </li>
                                    ))
                                )}
                            </ul>
                    </div>
                    <button className='btn-makit font-bold w-full h-auto mx-auto btn-hover-effect' onClick={submit}><p className='py-16'>Create Your AI</p></button>
                    </>
                )}
                
                {showSubmit && (
                    <>
                        <button className='btn-makit font-bold w-full h-auto mx-auto btn-hover-effect' onClick={submit}><p className='py-16'>Create Your AI</p></button>
                    </>
                )}

                {step > 0 && (
                    <button className='self-start border text-center border-makit px-16 rounded-2xl text-[#949494] bg-[#141414] hover:outline-none focus:outline-none backdrop-blur-3xl bg-opacity-20 font-bold w-auto h-auto mx-auto btn-hover-effect mt-4' onClick={handleBack}><p className='py-4'>←</p></button>
                )}
            </div>
        </div>
    )
}
