import social from "../Social";

export default function Social() {
    return (
        <div className="bg-blur bg-[#141414] bg-opacity-50 rounded-2xl p-8">
            <div className="grid grid-cols-1 gap-2">
                <div>
                    <p className="font-black uppercase text-xs text-[#F5F5F7]/[0.5]">Social</p>
                    <p className="text-xl text-syne">Get in Touch</p>
                </div>
                <div className="grid grid-cols-4 gap-2 mt-1">
                    {Object.keys(social).map((socialKey) => {
                        const socialDisplayName = socialKey.replace('_', ' ');
                        const socialPath = social[socialKey];

                        return (
                            <div key={socialPath} className='text-center text-[#E6E6EB]/[0.5] hover:text-[#E6E6EB]/[0.8] focus:hover:text-[#E6E6EB]/[0.8] rounded-xl p-4 border-makit border'>
                                <a href={socialPath} className="text-xl"><i className={`fab fa-${socialDisplayName}`}></i></a>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}