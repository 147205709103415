import React, { useState } from 'react';
import { handlePasswordReset } from '../../api/auth/AuthHandler';
import { errorAlert } from './handlers/Alerts';

import { useNavigate } from 'react-router-dom';

function Forgot() {
  
  const [ email, setEmail ] = useState('');
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const submit = async () => {
    if(email === '' || email === null || email === undefined) { 
      return errorAlert('Please, enter your email') 
    } else {
      handlePasswordReset(email);
      navigate('/signin')
    }
  }

  return (
    <div className='container mx-auto'>
      <div className='text-center px-4 lg:p-16 relative z-50'>
              <div className='grid grid-cols-1 gap-6 text-black'>
                <div>
                    <p className='text-white text-syne font-bold pb-4 opacity-50'>Please, enter your email</p>
                    <input className="btn-makit w-full lg:w-auto" placeholder="luca@makit.ai" type="email" value={email} onChange={handleEmailChange} autoComplete="email"/>
                </div>
                <div>
                  <button onClick={submit} type="submit" className="btn-submit right-0 mb-2">Recover your password</button>
                </div>
              </div>
          </div>
      </div>
  );
}
export default Forgot;