import React, { useEffect, useState, useCallback } from "react";
import { getAssistant, createAssistant, deleteAssistant } from "../handlers/APIHandler.js";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { getFilesFromAssistant } from "../handlers/APIHandler.js";
import { useAuth } from "../../Auth";
import { useDropzone } from "react-dropzone";
import { errorAlert } from "../handlers/Alerts.js";

function Settings({ assistantId }) {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [category, setCategory] = useState("");
  const [model, setModel] = useState("");
  const [instructions, setInstructions] = useState("");
  const [retrieval, setRetrieval] = useState(false);
  const [files, setFiles] = useState([]);

  const fetchAssistant = useCallback(async () => {
    setLoading(true);
    const assistantObj = await getAssistant(assistantId);
    setName(assistantObj.name);
    setInstructions(assistantObj.instructions);
    setModel(assistantObj.model);
    setDesc(assistantObj.desc);
    setVisibility(assistantObj.visibility);
    setCategory(assistantObj.category);
    setLoading(false);

    if (assistantObj.tools.length > 0) {
      const hasRetrievalTool = assistantObj.tools.some(tool => tool.type === "retrieval");
      setRetrieval(hasRetrievalTool);
    }

    const filesFromAssistant = await getFilesFromAssistant(assistantId);
    setFiles(filesFromAssistant);
  }, [assistantId]);

  const editAI = async () => {
    const tools = retrieval ? [{ type: "retrieval" }] : [];
    await createAssistant(
      assistantId,
      name,
      desc,
      model || "gpt-3.5-turbo-1106",
      instructions,
      tools,
      files,
      user,
      visibility,
      category
    );
  };

  const deleteAI = async () => {
    await deleteAssistant(assistantId, user.id);
    navigate("/");
  };

  useEffect(() => {
    fetchAssistant();
  }, [fetchAssistant]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
  }, []);

  const removeFile = (fileToRemove) => {
    const updatedFiles = files.filter(file => file.id !== fileToRemove.id);
    setFiles(updatedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  if (loading) {
    return (
      <div className="bg-blur bg-opacity-50 bg-[#141414] h-96 rounded-2xl relative"></div>
    );
  }

  return (
    <div className="relative">
      <div className="bg-blur bg-opacity-50 bg-[#141414] rounded-2xl text-white p-8 relative">
        <div className="mb-4">
          <p className="text-xl text-syne">Settings</p>
        </div>

        <div className="w-full mb-4">
          <p className="px-2 opacity-20 pb-1">Name of the AI</p>
          <input
            id="name"
            className="bg-transparent border border-makit text-sm rounded-lg focus:outline-none block w-full p-2.5 "
            placeholder="Legal Consultant"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="w-full mb-4">
          <p className="px-2 opacity-20 pb-1">Description</p>
          <input
            id="desc"
            className="bg-transparent border border-makit text-sm rounded-lg focus:outline-none block w-full p-2.5 "
            placeholder="Your Personal Legal Consultant"
            required
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
        </div>

        <div className="w-full mb-4">
          <p className="px-2 opacity-20 pb-1">Instructions</p>
          <textarea
            id="instructions"
            className="bg-transparent border border-makit text-sm rounded-lg focus:outline-none focus:ring-0 block w-full p-2.5 pb-8"
            required
            placeholder="Act like a Legal Consultant. You will need to answer user requests and be very detailed and professional in your responses."
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
          />
        </div>

        <div className="w-full mb-4">
          <p className="px-2 opacity-20 pb-1">Category</p>
          <select
            id="category"
            className="bg-transparent border border-makit text-sm rounded-lg focus:outline-none block w-full p-2.5 "
            required
            value={category}
            onChange={(e) => {
              if(e.target.value === "Educational" || e.target.value === "Research" || e.target.value === "Creativity" || e.target.value === "Productivity" || e.target.value === "Miscellaneous") {
                setCategory(e.target.value);
              } else {
                errorAlert("Invalid category");
              }}
            }
          >
            <option value="Educational" className="text-black">Educational</option>
            <option value="Research" className="text-black">Research</option>
            <option value="Creativity" className="text-black">Creativity</option>
            <option value="Productivity" className="text-black">Productivity</option>
            <option value="Miscellaneous" className="text-black">Miscellaneous</option>
          </select>
        </div>

        <div className="grid grid-cols-2">
          <div className="w-full mb-4">
            <p className="px-2 opacity-20 pb-1 inline-block">Public</p>
            <Switch
              checked={visibility}
              onChange={() => setVisibility(!visibility)}
              color="success"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
          <div className="w-full mb-4">
            <p className="px-2 opacity-20 pb-1 inline-block">Retrieval</p>
            <Switch
              checked={retrieval}
              onChange={() => setRetrieval(!retrieval)}
              color="success"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
        </div>

        <div className="w-full mb-4">
          <div {...getRootProps()} className="border border-d-makit p-6 opacity-50">
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here</p>
            ) : (
              <p className="cursor-pointer">Drag 'n' drop some files here, or <b>CLICK</b> to select files</p>
            )}
          </div>
          <ul>
            {files.map((file) => (
              <li key={file.id || file.name} className="opacity-20" id={file.id || file.name}>
                {file.filename || file.name} -{" "}
                <span className="opacity-50">
                  ({(file.bytes || file.size) / (1024 * 1024).toFixed(2)} MB){" "}
                  <span onClick={() => removeFile(file)}>
                    <i className="fa-regular fa-circle-xmark text-red-400"></i>
                  </span>
                </span>
              </li>
            ))}
          </ul>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="w-full self-center">
            <button
              onClick={deleteAI}
              className="text-red-700/[0.5] border border-red-700/[0.2] text-syne font-bold hover:bg-red-700/[0.5] hover:text-white focus:outline-none focus:ring-0 rounded-lg text-l w-full mx-auto p-4 text-center"
            >
              Delete
            </button>
          </div>
          <div className="w-full self-center">
            <button
              onClick={editAI}
              className="text-purple-700/[0.5] border border-purple-700/[0.2] text-syne font-bold hover:bg-purple-700/[0.5] hover:text-white focus:outline-none focus:ring-0 rounded-lg text-l w-full mx-auto p-4 text-center"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
