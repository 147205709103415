import React, { useState } from "react";
import { Squash as Hamburger } from 'hamburger-react'

import { useAuth } from "../../Auth";
import routes from "./navigation/Routes";

import { auth } from "../../../firebase";

function Navigation() {

    const { user } = useAuth();
    const [isOpen, setOpen] = useState(false)

    const signout = async() => {
        await auth.signOut();
        window.location.reload();
    }

    return (
        <>
            <div className='sticky text-white w-full z-30 py-4 hidden lg:block'>
                <div className='container mx-auto w-1/2'>
                    <div className='grid grid-cols-6 bg-nav px-8 py-4 rounded-full'>
                        <div className='self-center text-syne'>
                            <a href="/">
                                <p className='font-bold'>makit</p>
                            </a>
                        </div>
                        <div className='col-span-4 text-center self-center text-syne'>
                            {Object.keys(routes).map((routeKey) => {
                                const routeDisplayName = routeKey.replace('_', ' ');
                                const routePath = routes[routeKey];

                                return (
                                    <div key={routePath} className='inline-block text-[#E6E6EB]/[0.5] hover:text-[#E6E6EB]/[0.8] focus:hover:text-[#E6E6EB]/[0.8] mx-2'>
                                        <a href={routePath}>{routeDisplayName}</a>
                                    </div>
                                );
                            })}
                        </div>
                        { user ? (
                            <button onClick={signout}>
                                <div className='text-right self-center'>
                                    <p className='text-[#E6E6EB]/[0.5] hover:text-[#E6E6EB]/[0.8] focus:hover:text-[#E6E6EB]/[0.8]'>
                                        <span className="text-sm text-red-500/[0.3]"><i className="fas fa-sign-out-alt"></i> Logout</span>
                                    </p>
                                </div>
                            </button>
                        ) : (
                            <a href="/signin">
                                <div className='text-right self-center'>
                                    <p className='text-[#E6E6EB]/[0.5] hover:text-[#E6E6EB]/[0.8] focus:hover:text-[#E6E6EB]/[0.8]'>
                                        <span className="text-sm"><i className="fas fa-sign-in-alt"></i> Signin</span>
                                    </p>
                                </div>
                            </a>
                        )}
                        
                    </div>
                </div>
            </div>

            <div className='sticky text-white w-full z-30 py-4 block lg:hidden px-4 navMob'>
                <div className='container mx-auto w-full'>
                    <div className={`grid grid-cols-2 text-syne bg-nav px-8 py-4 ${isOpen ? "rounded-2xl" : "rounded-full"}`}>
                        <div className='self-center'>
                            <a href="/">
                                <p className='font-bold'>makit</p>
                            </a>
                        </div>
                        <div className='text-right self-center'>
                            <div className="float-right">
                                <Hamburger toggled={isOpen} toggle={setOpen} size={24} />
                            </div>
                        </div>
                        { isOpen && (
                            <div className="col-span-2 text-left mt-4">
                                {Object.keys(routes).map((routeKey) => {
                                    const routeDisplayName = routeKey.replace('_', ' ');
                                    const routePath = routes[routeKey];

                                    return (
                                        <div key={routePath} className='text-center text-[#E6E6EB]/[0.5] hover:text-[#E6E6EB]/[0.8] focus:hover:text-[#E6E6EB]/[0.8] text-xl mb-2'>
                                            <a href={routePath}>{routeDisplayName}</a>
                                        </div>
                                    );
                                })}
                                <div className="text-center text-xl mb-2">
                                { user ? (
                                    <button onClick={signout}>
                                        <div className='text-center mx-auto self-center'>
                                            <p className='text-[#E6E6EB]/[0.5] hover:text-[#E6E6EB]/[0.8] focus:hover:text-[#E6E6EB]/[0.8]'>
                                                <span className=" text-red-500/[0.3]"><i className="fas fa-sign-out-alt"></i> Logout</span>
                                            </p>
                                        </div>
                                    </button>
                                ) : (
                                    <a href="/signin">
                                        <div className='text-center mx-auto self-center'>
                                            <p className='text-[#E6E6EB]/[0.5] hover:text-[#E6E6EB]/[0.8] focus:hover:text-[#E6E6EB]/[0.8]'>
                                                <span><i className="fas fa-sign-in-alt"></i> Signin</span>
                                            </p>
                                        </div>
                                    </a>
                                )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </>
    )
}

export default Navigation;