import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { infoText } from "./app/handlers/Alerts";

export default function Pricing() {

    const navigate = useNavigate();

    useEffect(() => {
        navigate('/');
        infoText("Pricing page is not available yet")
    }, [navigate]);
}