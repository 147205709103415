import { useEffect, useState } from 'react'
import Card from './Card'
import { getPublicAssistantsByCategory } from '../../handlers/APIHandler'
import { Link } from 'react-router-dom'

export default function Category({ category }) {

    const [ assistants, setAssistants ] = useState([])

    useEffect(() => {
        getPublicAssistantsByCategory(category).then((data) => {
            setAssistants(data)
        })
    }, [category])
    
    return (
        <div className="bg-blur bg-opacity-50 bg-[#141414] rounded-2xl p-8">
            <div className="grid grid-cols-2 lg:grid-cols-2 gap-4 mb-4">
                <p className="text-white font-bold text-syne text-2xl">{ category }</p>
                <Link to={`/marketplace/${category}`}>
                    <p className='text-right text-white opacity-50 self-center'>Show all</p>
                </Link>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
                {assistants && (
                    assistants.map((assistant) => {
                        return <Card assistant={assistant} key={assistant.id}/>
                    })
                )}
            </div>
        </div>
    )
}