import Social from "./navigation/sidebar/Social";
import Profile from "./navigation/sidebar/Profile";
import Status from "./navigation/sidebar/Status";

export default function Sidebar({ user }) {
    return (
        <div>
            <div className="grid grid-cols-1 gap-4 lg:fixed self-start">
                <Profile user={user} />
                <Social />
                <Status />
            </div>
        </div>
    )
}