import React from "react";

const Message = ({ message, user }) => {

  return (
    <div className='bg-blur bg-opacity-50 bg-[#141414] rounded-2xl p-4'>
      <div className='p-2 rounded-3xl text-[#c9c9c9]'>
        { message.isBot ? (
          <div className="text-white text-syne">
            <p><i className='fa-solid fa-robot pr-2' />Makit</p>
          </div>
        ) : (
          <div className="text-white text-syne">
            {user ? (
              <p><i className='fa-solid fa-user pr-2' />{user.name} {user.surname}</p>
            ) : (
              <p><i className='fa-solid fa-user pr-2' />Guest</p>
            )}
            
          </div>
        )}
        {message.msg.split('\n').map((line, i) => (
          <p className="opacity-50" key={i}>{line}</p>
        ))}
            
      </div>
    </div>
  );
};

export default Message;
