import { Link } from 'react-router-dom';

export default function NeedAccount() {
    return (
        <div className="text-white relative container mx-auto z-30">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 px-4 lg:px-0">
                        <div className="grid col-span-1 lg:col-span-3 gap-8">
                            <div className="bg-blur bg-opacity-50 bg-[#141414] rounded-2xl p-8 text-center">
                                <p className="opacity-50 text-sm lg:text-xl font-bold pb-8">Please, you need to create an account <span className="hidden lg:inline-block"><br/></span>to start using this application</p>
                                <Link to="/signin">
                                    <button className="btn-makit font-bold w-full h-auto mx-auto btn-hover-effect"><p className="py-4 text-sm">Create an account or Login</p></button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
    )
}