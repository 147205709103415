import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'LawAI',
      data: [10, 15, 20, 11, 10, 25, 2],
      borderColor: 'rgba(255, 99, 132, 0.8)',
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
    },
    {
      label: 'SoccerAI',
      data: [15, 12, 72, 21, 3, 22, 9],
      borderColor: 'rgba(53, 162, 235, 0.8)',
      backgroundColor: 'rgba(53, 162, 235, 0.2)',
    },
    {
      label: 'DevAI',
      data: [13, 16, 55, 37, 32, 28, 65],
      borderColor: 'rgba(153, 50, 355, 0.8)',
      backgroundColor: 'rgba(153, 50, 355, 0.2)',
    },
  ],
};

function Dashboard() {
  return (
    <div className='grid grid-cols-4'>
      <div className='col-span-4 h-auto bg-[#121316] relative text-white p-16'>
        <div className='grid grid-cols-2 gap-16 text-syne z-20 relative'>
          <div className='col-span-2'>
            <p className='opacity-20 pb-4 text-syne'>Dashboard</p>
            <p className='text-2xl opacity-50 pb-4'>Total Users</p>
            <Line options={options} data={data} />
          </div>
          <div>
            <p className='text-2xl opacity-50 pb-4'>Daily User Interactions</p>
            <Line options={options} data={data} />
          </div>
          <div>
            <p className='text-2xl opacity-50 pb-4'>Daily Active Users</p>
            <Line options={options} data={data} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
