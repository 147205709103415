import React, { useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import Message from "./Message";
import OpenAI from "openai";

import { useAuth } from '../../Auth';

import { getAssistant } from '../../app/handlers/APIHandler'

import { getCredits, removeCredit } from '../handlers/APIHandler'
import { errorText } from '../handlers/Alerts'
import { getAuthor } from "../../../api/auth/AuthHandler";

const Chat = () => {

  const { assistantId } = useParams();
  const { currentUser, user } = useAuth();

  const [ assistant, setAssistant ] = useState([])
  const [ author, setAuthor ] = useState([])
  
  const [ isLoading, setIsLoading]  = useState(true)

  const [generatedImage, setGeneratedImage] = useState(null);
  const [isWaiting, setIsWaiting] = useState(false);
  const [question,setQuestion] = useState("")
  const [imageInput, setImageInput] = useState("")
  const [chat,setChat] = useState([])
  const [thread,setThread] = useState(null)
  const [openai,setOpenai] = useState(null)
  const chatRef = useRef(null)
  chatRef.current = chat

  useEffect(() => {
    if( assistantId === 'asst_xhGl1LNqsXkLWdHkNnzDjco1') {
      document.getElementById('banbg').style.backgroundImage = "url('https://cdn.discordapp.com/attachments/1017405900148965376/1206312740868591766/703a16e-AngelHYTOPIA.png?ex=65db8d49&is=65c91849&hm=6329d36e86af676355286368eff212f546008160869aecf8214e1f52d543df7d&')";
    } else if( assistantId === 'asst_BIpk6aA8mevlZqv7Erj85pXU') {
      document.getElementById('banbg').style.backgroundImage = "url('https://www.viatattica.it/wp-content/uploads/2019/07/5-tipologie-di-corsi-di-formazione-fra-cui-scegliere.jpg')";
    }
  })
  
  const genImage = async() => {
    setIsWaiting(true)

    try {
      const { data } = await openai.images.generate({
        model: "dall-e-3",
        n: 1,
        size: "1024x1024",
        prompt: imageInput || "Random image"
      });
      if (data && data[0] && data[0].url) {
        setGeneratedImage(data[0].url);
        setIsWaiting(false)
      } else {
        console.error("URL dell'immagine non presente nei dati restituiti.");
      }
    } catch (error) {
      console.error("Errore durante la generazione dell'immagine:", error);
    }
  }

  const getAnswer = async(threadId,runId) => {

    const getRun = await openai.beta.threads.runs.retrieve(
        threadId,
        runId
      );
    
    if(getRun.status === "completed"){
        const messages = await openai.beta.threads.messages.list(
            threadId
            );
        setIsWaiting((prev)=>false)
        setChat([...chatRef.current,{isBot:true,msg:messages.data[0].content[0].text.value}])
    }else{
        setTimeout(()=>getAnswer(threadId,runId),200)
    }
  }

  const askAssistant = async() => {
    if(assistantId === 'asst_BIpk6aA8mevlZqv7Erj85pXU') {
      let getQuestion = question
      setQuestion("")
      let chatList = [...chatRef.current,{isBot:false,msg:getQuestion}]
      setIsWaiting((prev)=>true)
      setChat(chatList)
      let getThread
      if(thread==null){
          getThread = await openai.beta.threads.create()
          setThread(getThread)
      } else{
          getThread = thread
      }
      
      await openai.beta.threads.messages.create(
          getThread.id,
          { role: "user", content: getQuestion }
      );
      const getRun = await openai.beta.threads.runs.create(
          getThread.id,
          { assistant_id: assistantId }
      );
      getAnswer(getThread.id,getRun.id)
    } else {
    if(currentUser == null) return errorText("You must be logged in to perform this action")
    
    if(await getCredits(currentUser.uid) > 0) {
      removeCredit(currentUser.uid, 1);
      let getQuestion = question
      setQuestion("")
      let chatList = [...chatRef.current,{isBot:false,msg:getQuestion}]
      setIsWaiting((prev)=>true)
      setChat(chatList)
      let getThread
      if(thread==null){
          getThread = await openai.beta.threads.create()
          setThread(getThread)
      } else{
          getThread = thread
      }
      
      await openai.beta.threads.messages.create(
          getThread.id,
          { role: "user", content: getQuestion }
      );
      const getRun = await openai.beta.threads.runs.create(
          getThread.id,
          { assistant_id: assistantId }
      );
      getAnswer(getThread.id,getRun.id)
    } else {
      errorText("You don't have enough credits to perform this action")
    }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isLoading) {
          await getAssistant(assistantId).then(async (data) => {
            setAssistant(data);
            setIsLoading(false);
            const authorData = await getAuthor(data.author);
            setAuthor(authorData);
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
    setOpenai(new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true }));
  
  }, [assistantId, isLoading]);

  return (
    <>
      <div className="bg-blur bg-opacity-50 bg-[#141414] py-4 mb-4 rounded-2xl">
        <p className="text-center text-white font-bold uppercase text-syne text-xl">{assistant.name}</p>
        <p className="text-white opacity-50 text-center pb-4 w-full px-4 lg:px-0 text-xs lg:text-base lg:w-2/3 mx-auto pt-2">{ assistant.desc }</p>
        
        { assistantId === 'asst_BIpk6aA8mevlZqv7Erj85pXU' ? (
          <p className="text-center text-white opacity-20">Creato da {author.name} {author.surname}</p>
        ) : (
          <p className="text-center text-white opacity-20">Created by {author.name} {author.surname}</p>
        )}
                
      </div>
      <div className="relative bg-blur rounded-2xl h-auto pb-32">
        <div className="grid grid-cols-1 gap-6 relative z-20 m-4">

          <div className='text-left bg-blur bg-opacity-50 bg-[#141414] rounded-2xl'>
            <div className='p-4 rounded-3xl text-[#525252]'>
        
              <div className="text-white text-syne">
                <p><i className='fa-solid fa-robot pr-2' />Makit</p>
                { assistantId === 'asst_BIpk6aA8mevlZqv7Erj85pXU' ? (
                  <p className="opacity-50">Ciao! Come posso aiutarti?</p>
                ) : (
                  <p className="opacity-50">Hey there! How can I help you?</p>
                )}
              </div>
            </div>
          </div>
          
          {chat.map((message, index) => (
            <div
              key={index}
              className={`${message.isBot ? "text-left" : "text-right"} text-white rounded-2xl w-full`}
            >
              <Message key={index} message={message} user={user} />
            </div>
          ))}
        </div>

        <div className='p-4 absolute bottom-4 w-full'>

          {assistant.model !== "dall-e-2" ? (

            <div className='container mx-auto'>
                <div className="grid grid-cols-10 bg-blur bg-opacity-50 bg-[#141414] py-4 px-8 rounded-2xl border-makit">

                      <div className="col-span-9">
                          <input id="question" className="focus:outline-none w-full h-full bg-transparent input-ai text-[#7c7c7c]" placeholder="Ask a question" required value={question} disabled={isWaiting} onKeyDown={(e) => {e.code === "Enter" && !e.shiftKey && askAssistant();}} onChange={(e)=>setQuestion(e.target.value)}/>
                      </div>

                      <div className="col-span-1">
                          <button
                              className='w-full h-10 text-[#7c7c7c] rounded-md flex items-center justify-end'
                              onClick={askAssistant}
                              disabled={isWaiting}
                          >
                              {isWaiting ? (
                                <div className="animate-spin">
                                    <p><i className="fas fa-cog text-xl"></i></p>
                                </div>
                              ) : (
                                <>
                                  <p><i className="fas fa-paper-plane text-xl"></i></p>
                                </>
                              )}
                          </button>
                      </div>
                </div>
              </div>

              ) : (

                <div className='container mx-auto w-full h-full relative'>
                  <div className='grid grid-cols-1'>

                    {generatedImage && (
                      <div className="mb-16 w-full text-center p-4 border-makit bg-[#191a1e] rounded-2xl">
                        <img className='text-center mx-auto rounded-2xl' src={generatedImage} alt={generatedImage.name} style={{ maxWidth: '100%', maxHeight: '300px' }} />
                      </div>
                    )}

                    <div className="grid grid-cols-1 sm:grid-cols-10 py-4 px-8 rounded-2xl bg-[#191a1e] text-white-mid border-makit">

                        <div className="sm:col-span-9 col-span-8">
                            <input id="imageGen" className="focus:outline-none w-full h-full bg-transparent input-ai text-[#7c7c7c]" placeholder="Ask to generate an image" required value={imageInput} disabled={isWaiting} onKeyDown={(e) => {e.code === "Enter" && !e.shiftKey && genImage();}} onChange={(e)=>setImageInput(e.target.value)} />
                        </div>

                        <div className="sm:col-span-1 col-span-2">
                            <button
                                className=' w-full h-10 text-[#7c7c7c] rounded-md flex items-center justify-end'
                                onClick={genImage}
                                disabled={isWaiting}
                              >
                                {isWaiting ? (
                                  <div className="animate-spin">
                                      <p><i className="fas fa-cog text-xl"></i></p>
                                  </div>
                                ) : (
                                  <>
                                    <p><i className="fas fa-image text-xl"></i></p>
                                  </>
                                )}
                                </button>
                          </div>
                    </div>
                  </div>
                </div>
              )}
        </div>
      </div>
    </>
  );
};

export default Chat;