import { auth } from '../../firebase';
import { db } from '../../firebase';
import { sendPasswordResetEmail, sendEmailVerification, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { setDoc, collection, getDoc, doc, getDocs, query, where } from "firebase/firestore";
import { createdAccountAlert, errorText, successText } from '../../views/app/handlers/Alerts';

// Function to handle authentication state changes
export const handleAuthentication = () => {
    return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, user => {
            if (user) {
                resolve(true);
            } else {
                resolve(false);
            }
        });
    });
};

// Get user's db document
export const getUserInfo = async (userId) => {
    try {
        const userDocRef = doc(db, "Users", userId);
        const docSnap = await getDoc(userDocRef);

        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            console.log("No such document!");
            return null;
        }
    } catch (error) {
        console.log("Error getting document:", error);
        throw error; // Rilancia l'errore per consentire al chiamante di gestirlo
    }
};

// Function to handle user registration
export const handleSignUp = (name, surname, email, date, password, how) => {
    createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            if (user) {
                const userDocRef = doc(collection(db, "Users"), user.uid);
                setDoc(userDocRef, {
                    id: user.uid,
                    email: email,
                    name: name,
                    surname: surname,
                    date: date,
                    how: how,
                    credits: 50,
                    premium: false,
                    apps: [],
                    emailVerified: false
                })
                .then(() => {
                    createdAccountAlert()
                })
                .catch((error) => {
                    console.error('Error creating user document:', error);
                });
            } else {
                console.error('User object is not available.');
            }
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log('Error signing up.', errorCode, errorMessage);
        });
};

//Email exists in the database
export const emailExists = async (email) => {
    const userCollectionRef = collection(db, "Users");
    const querySnapshot = await getDocs(query(userCollectionRef, where("email", "==", email)));
    if (querySnapshot.size > 0) {
        return true;
    } else {
        return false;
    }
};

// Recover password
export const handlePasswordReset = (email) => {
    emailExists(email).then((exi) => {
        if(exi) {
            sendPasswordResetEmail(auth, email)
                .then(() => {
                    successText('Password reset email sent')
                })
                .catch((error) => {
                    errorText('Error sending password reset email');
                    console.log(error)
                });
            } else {
                errorText('Email not found');
            }
    })
}

// Function to handle user login
export const handleLogin = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
        return true; // Restituisci true se il login ha successo
    } catch (error) {
        return false; // Restituisci false se si verifica un errore durante il login
    }
};

// Function to get Author from Assistant ID
export const getAuthor = async (authorId) => {
    try {
        const authorDocRef = doc(db, "Users", authorId);
        const docSnap = await getDoc(authorDocRef);

        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            console.log("No such document!");
            return null;
        }
    } catch (error) {
        console.log("Error getting document:", error);
        throw error; // Rilancia l'errore per consentire al chiamante di gestirlo
    }
}

// Function to get Application from Assistant ID
export const getAssistant = async (assistantId) => {
    try {
        const assistantDocRef = doc(db, "Assistants", assistantId);
        const docSnap = await getDoc(assistantDocRef);

        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            console.log("No such document!");
            return null;
        }
    } catch (error) {
        console.log("Error getting document:", error);
        throw error; // Rilancia l'errore per consentire al chiamante di gestirlo
    }
};


// Function to send a verification email to the user
export const handleSendEmailVerification = () => {
    sendEmailVerification(auth.currentUser)
        .then(() => {
            successText('Verification email sent');
        })
        .catch(() => {
            errorText('Error sending verification email');
        });
};