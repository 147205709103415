import { Link } from 'react-router-dom';

export default function YourApps({assistants}) {
    return (
        <>
            <div className="bg-blur bg-opacity-50 bg-[#141414] rounded-2xl p-8">

                <div className="grid grid-cols-3 text-syne text-[#F5F5F7]">
                    <div>
                        <p className="text-xl lg:text-2xl">Applications</p>
                    </div>
                    <div className="text-right self-center col-span-2">
                        <Link to={"/apps/create"}>
                            <p className="opacity-50 hover:opacity-100 focus:opacity-100"><span className='bg-blur bg-black/[0.2] px-4 lg:px-8 py-2 rounded-xl'>Create your AI</span></p>
                        </Link>
                    </div>
                </div>
                    
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-8">
                    {assistants.map((assistant)=>
                        <Link to={"/apps/edit/"+assistant.id} key={assistant.id}>
                            <div className="border-makit text-syne px-8 py-6 rounded-xl card-bg text-[#E6E6EB]/[0.5] hover:text-[#E6E6EB]/[0.8] focus:text-[#E6E6EB]/[0.8]" key={assistant.id}>
                                <div className="text-center font-bold">{assistant.name}</div>
                            </div>
                        </Link>
                    )}
                </div>
                        
            </div>
        </>
    )
}