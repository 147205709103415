import { useState, useEffect } from "react";
import { getAuthor } from "../../../../api/auth/AuthHandler";
import { getAssistant } from "../../handlers/APIHandler";
import { Link } from "react-router-dom";

export default function Card({ assistant }) {

    const [ author, setAuthor ] = useState({});
    const maxDescLength = 90;
    const [ truncatedDesc, setTruncatedDesc ] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getAssistant(assistant.id).then(async (data) => {
                    const authorData = await getAuthor(data.author);
                    setAuthor(authorData);
                })
            } catch (error) {
              console.error(error);
            }
        }

        fetchData();
    }, [assistant.id]);

    useEffect(() => {
        if (assistant.desc.length > maxDescLength) {
            setTruncatedDesc(assistant.desc.substring(0, maxDescLength) + '...');
        } else {
            setTruncatedDesc(assistant.desc);
        }
    }, [assistant.desc, maxDescLength]);

    return (
        <Link to={`/apps/${assistant.id}`}>
            <div className="relative border-makit w-full rounded-2xl h-32">
                <div className="text-white font-black p-4">
                    <p className="pb-1">{ assistant.name }</p>
                    <p className="text-xs opacity-20 font-light pb-2 ">{ truncatedDesc }</p>
                </div>
                <div className="absolute bottom-2 left-4">
                    <p className="text-xs opacity-30 text-white font-medium">By { author.name } { author.surname }</p>
                </div>
            </div>
        </Link>
    )
}
