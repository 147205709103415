import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip'

export function Modal({ updateProp, type, text, subtext, evidence, bmodel, tooltip, tooltipText }) {

    const [prop, setProp] = useState(null);

    const changeProp = (newProp) => {
        setProp(newProp);
    };

    const submit = () => {
        updateProp(prop);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            submit();
        }
    };

    const viewer = () => {
        let x = document.getElementById("password");
        let eye = document.getElementById("eye");

        if (x.type === "password") {
            x.type = "text";
            eye.classList.replace('fa-eye', 'fa-eye-slash');
            eye.classList.replace('far', 'fas');
        } else {
            x.type = "password";
            eye.classList.replace('fa-eye-slash', 'fa-eye');
            eye.classList.replace('fas', 'far');
        }
    };

    return (
        <div className='btn-makit font-bold w-full h-auto mx-auto btn-hover-effect'>
            <div className='my-8'>

                {evidence === 'text' ? (
                    <>
                        <a className='text-white text-syne font-bold text-xl inline-block"'>{text}</a>
                        { tooltip &&
                            <>
                                <Tooltip id="my-tooltip" />
                                <a className="inline-block" data-tooltip-id="my-tooltip" data-tooltip-content={tooltipText}><i className="fas fa-info-circle ml-2 text-[#515d9f]"></i></a>
                            </>
                        }
                        {subtext && <p className='text-white text-syne font-bold text-xl opacity-50'>{subtext}</p>}
                    </>
                ) : (
                    <>
                        <a className='text-white text-syne font-bold text-xl opacity-50 inline-block'>{text}</a>
                        { tooltip &&
                            <>
                                <Tooltip id="my-tooltip" />
                                <a className="inline-block" data-tooltip-id="my-tooltip" data-tooltip-content={tooltipText}><i className="fas fa-info-circle ml-2 text-[#515d9f]"></i></a>
                            </>
                        }
                        {subtext && <p className='text-white text-syne font-bold text-xl'>{subtext}</p>}
                    </>
                )}

                <div className='grid grid-cols-5 mt-8 px-4 lg:px-0 lg:block self-start'>

                    <div className='col-span-4 inline-block'>

                        {type === 'text' && (
                            <input className='procedural-type w-full lg:w-auto' type="text" onChange={(e) => changeProp(e.target.value)} onKeyPress={handleKeyPress} required />
                        )}
                        {type === 'email' && <input className='procedural-type w-full lg:w-auto' type="email" onChange={(e) => changeProp(e.target.value)} onKeyPress={handleKeyPress} required />}
                        {type === 'password' &&
                            <>
                                <button className="absolute text-xl p-4 text-[#949494] hover:outline-none focus:outline-none " onClick={viewer}><i id="eye" className="far fa-eye"></i></button>
                                <input id="password" className='procedural-type w-full lg:w-auto' type="password" onChange={(e) => changeProp(e.target.value)} onKeyPress={handleKeyPress} required />
                            </>
                        }
                        {type === 'date' && <input className='procedural-type text-white w-full lg:w-auto' type="date" onChange={(e) => changeProp(e.target.value)} onKeyPress={handleKeyPress} required />}

                        {type === 'select' && (
                            bmodel === null ? (
                                <select className='procedural-type text-black font-light w-full lg:w-auto' onChange={(e) => changeProp(e.target.value)} defaultValue={'DEFAULT'} onKeyPress={handleKeyPress}>
                                    <option value="DEFAULT" disabled hidden>Select an option</option>
                                    <option className="text-black text-center"  value="1">Word of Mouth</option>
                                    <option className="text-black text-center"  value="2">Social Media</option>
                                    <option className="text-black text-center"  value="3">Search Engines</option>
                                    <option className="text-black text-center" value="4">Events</option>
                                    <option className="text-black text-center" value="5">Other</option>
                                </select>
                            ) : (
                                <select className='procedural-type font-light w-full lg:w-auto' onChange={(e) => changeProp(e.target.value)} defaultValue={'DEFAULT'} onKeyPress={handleKeyPress}>
                                    <option className="text-black text-center" value="DEFAULT" disabled hidden>Select an option</option>
                                    <option className="text-black text-center" value="gpt-3.5-turbo-1106">GPT-3.5 Turbo</option>
                                    <option className="text-black text-center" value="gpt-3.5-turbo-1106">GPT-4</option>
                                </select>
                            )
                        )}

                        {type === 'retrive' && (
                            <select className='procedural-type font-light w-full lg:w-auto' onChange={(e) => changeProp(e.target.value)} defaultValue={false} onKeyPress={handleKeyPress}>
                                <option className="text-black text-center" value="DEFAULT" disabled hidden>Select an option</option>
                                <option className="text-black text-center" value={true}>Yes</option>
                                <option className="text-black text-center" value={false}>No</option>
                            </select>
                        )}

                        <div className='absolute hidden lg:inline-block '>
                            <button className='relative lg:mx-4 btn-eye bg-transparent' onClick={submit}><i className="fas fa-arrow-right"></i></button>
                        </div>

                    </div>
                    <div className='relative inline-block lg:hidden'>

                        <button className='relative lg:mx-4 btn-eye bg-transparent' onClick={submit}><i className="fas fa-arrow-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    );
}
