import React, { useState, useEffect } from 'react';
import { auth } from '../../firebase';
import { emailExists } from './handlers/APIHandler';

import { errorText } from './handlers/Alerts';
import { Modal } from './components/auth/Modal';

import { useNavigate } from 'react-router-dom';
import { handleSignUp } from '../../api/auth/AuthHandler';

function Signup() {

    const navigate = useNavigate();
  
    const [name, setName] = useState(null);
    const [surname, setSurname] = useState(null);
    const [email, setEmail] = useState(null);
    const [date, setDate] = useState(null);
    const [password, setPassword] = useState(null);
    const [how, setHow] = useState(null);
  
    const [showNameModal, setShowNameModal] = useState(true);
    const [showSurnameModal, setShowSurnameModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showDateModal, setShowDateModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showPasswordConfirmModal, setShowPasswordConfirmModal] = useState(false);
    const [showHowModal, setShowHowModal] = useState(false);
    const [showSubmit, setShowSubmit] = useState(false);
  
    const [ currentUser, setCurrentUser ] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
        });

        return () => {
        unsubscribe();
        };
    }, []);

    useEffect(() => {
        if(currentUser === true) {
            navigate('/apps');
        }
    })

    const validateText = (name) => {

        if (name.length < 2 || name.length > 21) {
            errorText("It must be between 2 and 21 characters");
            return false;
        }

        const validCharacters = /^[a-zA-ZÀ-ÿ- ']+$/;
        if (!validCharacters.test(name)) {
            errorText("It can only contain letters, spaces, hyphens, and apostrophes");
            return false;
        }

        if (name.trim() !== name) {
            errorText("It cannot start or end with a space");
            return false;
        }

        return true;
    };

    const validatePassword = (password) => {
        
        //Contains at least one digit
        const containsDigit = /\d/;
        if (!containsDigit.test(password)) {
            errorText("It must contain at least one digit");
            return false;
        }

        //Contains at least one lowercase letter
        const containsLowercase = /[a-z]/;
        if (!containsLowercase.test(password)) {
            errorText("It must contain at least one lowercase letter");
            return false;
        }

        //Contains at least one uppercase letter
        const containsUppercase = /[A-Z]/;
        if (!containsUppercase.test(password)) {
            errorText("It must contain at least one uppercase letter");
            return false;
        }

        //Contains at least one special character
        const containsSpecial = /[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]/;
        if (!containsSpecial.test(password)) {
            errorText("It must contain at least one special character");
            return false;
        }

        //Contains at least 8 characters
        if (password.length < 8 || password.length > 21) {
            errorText("It must be between 8 and 21 characters");
            return false;
        }

        return true;
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(emailPattern.test(email)) {
            return true;
        } else {
            errorText("Invalid email!");
            return false;
        }
    };

    const updateName = (prop) => {
        if (prop != null && validateText(prop)) {
            setName(prop);
            setShowNameModal(false);
            setShowSurnameModal(true);
        }
    };

    const updateSurname = (prop) => {
        if (prop != null && validateText(prop)) {
            setSurname(prop);
            setShowSurnameModal(false);
            setShowEmailModal(true);
        }
    };

    const updateEmail = (prop) => {
        if(prop !== null && validateEmail(prop)) {
            emailExists(prop).then((result) => {
                if(result === true) {
                    errorText("This email is already in use");
                    return;
                } else {
                    setEmail(prop);
                    setShowEmailModal(false);
                    setShowPasswordModal(true);
                }
            })
        }
    }

    const updateDate = (prop) => {
        setDate(prop);
        setShowDateModal(false);
        setShowPasswordModal(true);
    }

    const updatePassword = (prop) => {
        if(validatePassword(prop)) {
            setPassword(prop);
            setShowPasswordModal(false);
            setShowPasswordConfirmModal(true);
        }
    }

    const updatePasswordConfirm = (prop) => {
        if (prop !== password) {
            errorText("The passwords are not the same");
            return;
        } else {
            setShowPasswordConfirmModal(false);
            setShowHowModal(true);
        }
    }

    const updateHowConfirm = (prop) => {
        if(prop !== null) {
            setHow(prop);
            setShowHowModal(false);
            setShowSubmit(true);
        }
    }

    const submit = async() => {
        handleSignUp(name, surname, email, date, password, how);
        navigate('/success');
    };

  return (
    <div className='col-span-4 h-auto min-h-screen relative text-white px-4 lg:px-0'>
      <div className='text-center lg:px-24 py-24 relative z-50'>
            {showNameModal && <Modal updateProp={updateName} type="text" text="Hey! What is your first name?" />}
            {showSurnameModal && <Modal updateProp={updateSurname} type="text" text={`Nice to meet you, ${name}`} subtext="What is your last name?" evidence="subtext" required />}
            {showEmailModal && <Modal updateProp={updateEmail} type="email" text={`${name} ${surname}, I like it!`} subtext="What is your email?" evidence="subtext" required/>}
            {/* {showDateModal && <Modal updateProp={updateDate} type="date" text="When were you born? " subtext evidence="text" required/>} */}
            {showPasswordModal && <Modal updateProp={updatePassword} type="password" text={`Be careful, ${name}`} subtext="What is your password?" evidence="subtext" required/>}
            {showPasswordConfirmModal && <Modal updateProp={updatePasswordConfirm} type="password" text={`Nice password`} subtext="Can you reconfirm that?" evidence="subtext" required/>}
            {showHowModal && <Modal updateProp={updateHowConfirm} type="select" text="Last question..." subtext="How did you discover us?" evidence="subtext" bmodel={null} />}
            {showSubmit && <button className='btn-makit font-bold w-full h-auto mx-auto btn-hover-effect' onClick={submit}><p className='py-16'>Click here to create your account</p></button>}
        </div>
    </div>
  );
}

export default Signup;