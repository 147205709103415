import { useEffect, useState } from "react"
import { getPublicAssistantsByCategory } from "./app/handlers/APIHandler"
import Card from "./app/components/market/Card"
import { useParams } from "react-router-dom"

export default function ShowAll() {

    const { category } = useParams();

    const [ assistants, setAssistants ] = useState([])

    useEffect(() => {
        getPublicAssistantsByCategory(category).then((data) => {
            setAssistants(data)
        })
    }, [category])

    return (
        <div className="lg:px-0 px-4">  
            <div className="container lg:mx-auto lg:px-0 bg-blur bg-opacity-50 bg-[#141414] rounded-2xl  py-8 lg:p-8">
                <div className="grid grid-cols-1 gap-8 mx-8">
                    <p className="text-white font-bold text-syne text-2xl">{ category }</p>
                    {assistants && (
                        assistants.map((assistant) => {
                            return <Card assistant={assistant} key={assistant.id}/>
                        })
                    )}
                </div>
            </div>
        </div>
    )
}