import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';

import 'react-tooltip/dist/react-tooltip.css'
import "./assets/css/App.css";

import { ToastContainer } from 'react-toastify';

import Editor from './views/app/Editor';
import Signin from './views/app/Signin';
import Dashboard from './views/app/Dashboard';
import ErrorPage from './views/errors/ErrorPage';
import Apps from './views/Apps';
import Creator from './views/Creator';
import Pricing from './views/Pricing';

import AppPage from './views/AppPage';
import Navigation from './views/app/components/Navigation';
import Dots from './views/app/components/misc/Dots';

import Signup from './views/app/Signup';
import Forgot from './views/app/Forgot';

import { AuthProvider } from './views/Auth';
import Marketplace from './views/Marketplace';
import ShowAll from './views/ShowAll';

const App = () => {

  return (
    <>
    <Analytics />
    <AuthProvider>
    <div className='bg-[#141414] min-h-screen'>
        <Dots />
        <Navigation />
        <div className="relative w-full h-full py-8 lg:py-16 z-30">
          <BrowserRouter>
              <Routes>
                <Route path="/" element={<Apps />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/signin" element={<Signin />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/forgot" element={<Forgot />} />
                <Route path="/apps/edit/:assistantId" element={<Editor />} />
                <Route path="/apps/create" element={<Creator />} />
                <Route path="/apps/:assistantId" element={<AppPage />} />
                <Route path="/marketplace" element={<Marketplace />} />
                <Route path="/marketplace/:category" element={<ShowAll />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/hytopia" element={<Navigate to="/apps/asst_xhGl1LNqsXkLWdHkNnzDjco1" />}/>
                <Route path="/success" element={<Navigate to="/" />}/>
                {/* Handle unmatched routes */}
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            
          </BrowserRouter>
        </div>
      <ToastContainer />
    </div>
    
    </AuthProvider>
    </>
  );
};

ReactDOM.createRoot(document.getElementById('root')).render(
  <App />
);